import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import BlogArtigosComponent from "../components/blog-artigos";
import Footer from "../components/footer"


const RecursosEstoquesPage = () => {

  useEffect(() => {
	 AOS.init()
  }, []);


  return (
	<main>
	  <Seo
		title="Gestão de Estoque Eficiente e Segura - Controle de Estoques Online e Locais de Armazenamento 📦"
		description="Otimize a gestão de estoques com o Sistema wEstoque. Controle múltiplos estoques, gerencie transferências entre filiais, organize fornecedores e planeje compras de forma eficiente. Experimente grátis e veja como nosso software pode transformar a gestão do seu estoque."
		keywords={[
			`Gestão de estoque eficiente`,
			`Controle de estoque online`,
			`Software de gestão de estoque`,
			`Transferência entre estoques`,
			`Controle de fornecedores`,
			`Planejamento de compras`,
			`Inventário de produtos`,
			`Gestão de múltiplos estoques`,
			`Sistema de controle de estoque`,
			`Controle estoque online`,
			`gestão de estoques`,
			`controle de estoques online`,
			`sistema multiloja`,
			`gestão de fornecedores`,
			`controle de compras e vendas`,
			`transferência de estoques entre filiais`,
			`programa para gestão de estoques`,
			`inventário de produtos e históricos`,
			`sistema de controle de pedidos`,
			`vendas pdv`,
			`controle de multiplas lojas`,
			`controle de locais online`,
			`pdv online`,
			`estoques diferentes`,
			`venda de outra loja`,
			`amplie frente de loja`,
			`emita notas fiscais`,
		]}
	  />

	  <Header />
 
	  <section style={{paddingTop: `100px`}} className="overflow-x-hidden">

		<div className="max-w-6xl mx-auto">

			<div className="py-8 md:py-15">
			
			<div className="grid gap-3 sm:gap-8">
				
							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
										src="../images/controledeestoque-topo-westoque.png"
										alt="Sistema wEstoque - rograma de Gestão de Estoque"
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={350}
										className="max-w-full mx-auto h-auto"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-12 md:pb-16 items-center">
										<h1 className="text-center text-5xl font-bold" >
                                            Programa para Gestão de Estoque Eficiente e Segura!
										</h1>
										<br/>
										<p className="text-xl text-gray-700 mb-4">O estoque é um dos setores da empresa que devemos controlar com muito cuidado, pois qualquer erro no gerenciamento de um estoque pode trazer uma sequência de prejuízos. Para evitar esse tipo de problema, conte com o wEstoque, com a nossa ferramenta você pode gerenciar produtos, movimentações entre filiais, transferências entre estoques, pedidos, compras, fornecedores e muito mais.</p>
										<br/>
										<form action="/usar">
										<div className="absolute w-full items-stretch mb-3 mx-auto text-center px-1 md:px-10">
										<div className="border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-yellow-500 transition-colors shadow-lg">
											<input type="email" id="txtEmail" name="txtEmail" className="w-full pl-3 pr-10 py-2 rounded-xl border-0" placeholder="Digite Seu E-mail"  />
											<button type="submit" className="block w-25 h-7 text-center font-extrabold text-sm leading-0 absolute top-2 right-2 focus:outline-none hover:text-gray-900 transition-colors text-green-500 px-1 md:px-10"><span role="img" aria-label="go emoji">✅</span>&nbsp; Experimente Grátis Agora e Otimize Seu Estoque! </button>  
										</div>
										</div>
									</form>
									</div>
								</div>
							</div>



							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-28">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
											src="../images/recursos-inventario-produtos.png"
											alt="Inventário de Produtos e Históricos"
											placeholder="blurred"
											quality={100}
											className="w-full max-w-md"
										/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left align-top" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-semibold text-westoque-500"><span className="md:hidden" role="img" aria-label="inventario emoji">🗃</span> Inventário de Produtos e Históricos</h4>

											<div className="mt-1 text-md md:text-xl">
											Com o nosso recurso de controle de estoque você poderá facilmente gerar inventários que facilitarão o gerenciamento do seu estoque.<br/>Tenha autonomia no controle de todas as lojas e verifique estoques e quantidades a qualquer momento.
											</div>
										</div>
									</div> 
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

										<StaticImage
											src="../images/recursos-multiplos-estoques.png"
											alt="Controle Múltiplos Estoques"
											placeholder="blurred"
											quality={100}
											className="w-full max-w-md"
										/>
										
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-500"><span className="md:hidden" role="img" aria-label="etiqueta emoji">🏷</span> Controle Múltiplos Estoques</h4>

											<div className="mt-1 text-md md:text-xl">
											O wEstoque é um software de controle de estoque completo. Tenha o controle de mais de um estoque (depósitos, armazéns, etc). Você pode fazer a gestão das quantidades de seus produtos em vendas de qualquer estoque...
                                            Faça transferências e movimentações entre estoques de forma rápida e fácil.
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-transferencia-estoques.png"
										alt="Transferência de Estoques"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left md:order-1" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-500"><span className="md:hidden" role="img" aria-label="transferencia emoji">🧮</span> Transferência de Estoques</h4>

											<div className="mt-1 text-md md:text-xl">
											Com nosso programa para gerenciamento de estoque é facil a gestão do estoque entre lojas. Tudo de forma simples, prática e ágil, evitando perdas de mercadorias e valores, efetuando transferência de mercadorias disponíveis de uma loja para outra. Com a transferência de estoques você vai conseguir manter o controle de estoques atualizado e ter um histórico de movimentação de estoque para ter o total controle da sua empresa.
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-controle-fornecedores.png"
										alt="Controle de Fornecedores"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-500"><span className="md:hidden" role="img" aria-label="fornecedores emoji">📚</span> Controle de Fornecedores</h4>

											<div className="mt-1 text-md md:text-xl">
											Organize todos seus fornecedores e facilite ainda mais para seu planejamento, e assim centralizando todas as informações necessárias para prevenir falhas e imprecisões no controle de estoque. Cadastre múltiplos contatos e gerencie os registros de forma fácil.
											</div>
										</div>
									</div>
								</div>
							</div>


                            <div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-controle-compras.png"
										alt="Controle de Compras"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left md:order-1" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-500"><span className="md:hidden" role="img" aria-label="compras emoji">📖</span> Controle de Compras</h4>

											<div className="mt-1 text-md md:text-xl">
											
Evite desperdícios ou prejuízos, com um planejamento e controle de compras e estoque, você pode controlar a entrada e saída de mercadorias, gerenciar ordens de compras.
											</div>
										</div>
									</div>
								</div>
							</div>

                            


							<div className="md:grid md:grid-cols-12 md:gap-6 items-center mt-36">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">
				  					<StaticImage
										src="../images/call-pdv-roupas.png"
										alt="Controle sua loja com o sistema wEstoque"
										placeholder="blurred"
										layout="fullWidth"
										quality={100}
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-6 md:pb-8">
										<h1 className="text-center text-5xl font-bold mb-2 p-1" >
											<span className="underline decoration-westoque-400">Controle de Vários Estoques</span> e <span className="underline decoration-westoque-400"> Lojas / Empresas</span> de um único acesso e <span className="underline decoration-sky-500">seguro</span>!
										</h1> 
										<div className="text-xl text-gray-700 mb-2 w-full">Com o <b className="text-2xl">w<span className="text-westoque-500">Estoque</span></b> ganha tempo!</div>
									</div>
									<a className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out" href="/usar ">EXPERIMENTE GRÁTIS</a>
								</div>
							</div>

							<BlogArtigosComponent />

						</div>
					</div>
				</div>
			</section>

	  <br/><br/>
	  <Footer />


	</main>
  )
}

export default RecursosEstoquesPage
