import React from "react"
import { graphql, StaticQuery } from "gatsby"

function findImage(html){
    const img = html.match(/<img.*?src="(.*?)"/)
    return img? img[1] : false
}

const BlogArtigos = ({ data }) => {
 
    const allPosts = data.allFeedwEstoqueBlog.nodes


    const posts = allPosts
    posts.sort(() => Math.random() - 0.5)

    return (
    <>
        <div className="w-full text-center mt-20 h-full">
          <div className="font-semibold text-3xl text-purple-600 mb-2">Veja no nosso Blog</div>
        </div>
        
        <div id="sectionBlog" className="grid gap-2 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 items-center justify-center px-3 py-3 max-w-full content-start h-full" >
        {

        posts.slice(0,4).map((post, _i) => {

        return (
        <div className="bg-white rounded-md shadow-sm p-2 max-w-xs min-w-full  aos-init aos-animate" data-aos="fade-in" key={_i}>
            <div className="flex flex-row md:flex-col">
              <a href={post.link} rel="noreferrer" target="_blank" key={post.id}>
                  <img src={findImage(post.content.encoded.toString())} alt={post.title} className="h-64 object-cover rounded-md max-h-20 max-w-20 md:max-h-60 md:w-full" />
              </a>
              <h1 className="md:text-xl sm:text-base text-yellow-500  hover:text-yellow-700 px-3 md:px-1"><span className="underline">{post.title}</span></h1>    
              
            </div>
            <p className="text-xs md:text-sm text-gray-600 float-left p-2">{post.contentSnippet.slice(0, post.contentSnippet.search("O post "))}</p>
        </div>      
        )
        })

        }

        </div>
        <br/>        
    </>
    )
}

export default function BlogArtigosComponent() {
    return (
    <StaticQuery
      query={graphql`
      query {
        allFeedwEstoqueBlog {
            nodes {
              id
              link
              title
              content {
                encodedSnippet
                encoded
              }
              contentSnippet
            }
          }
    }`} render={data => <BlogArtigos data={data} />} />
    )
}